.policyContainer {
    max-width: 752px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1;
}

@media screen and (max-width: 900px) {
    .policyContainer {
        gap: 16px;
        padding: 0 16px 16px 16px;
    }
}

.header {
    color: #0F1214;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@media screen and (max-width: 900px) {
    .header {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.textBot {
    color: #0F1214;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}