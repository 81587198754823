.listItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.heading {
    color: #0F1214;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

@media screen and (max-width: 900px) {
    .heading {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }
}

.text {
    color: #7F7E84;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}