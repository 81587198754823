.bodyFlex {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 900px) {
  .bodyFlex {
    gap: 24px;
  }
}

.flexSport {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-left: 40px;

  flex-basis: 55vh;
  flex-grow: 1;

  padding-right: 40px;
  overflow-y: scroll;
}

@media screen and (max-width: 900px) {
  .flexSport {
    margin-left: 10px;
  }
}

.flexSport::-webkit-scrollbar {
  width: 4px;
}

@media screen and (max-width: 900px) {
  .flexSport::-webkit-scrollbar {
    display: none;
  }
}

.flexSport::-webkit-scrollbar-thumb {
  background: #C3C3C5;
  border-radius: 40px;
  width: 4px;

}

.flexSport::-webkit-scrollbar-track {
  display: none;
}

.flexSport::-webkit-scrollbar-button {
  display: none;
}

.passionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.passionHeader {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #0F1214;
}

.flexContainer {
  box-sizing: content-box;
  max-width: 622px;

  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  row-gap: 20px;
  column-gap: 16px;


}

@media screen and (max-width: 900px) {
  .flexContainer {
    max-width: 343px;
    padding: 0;
    gap: 12px;
  }
}

.flexItem {
  box-sizing: border-box;
  text-align: center;
  min-height: 51px;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #0F1214;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: 2px solid transparent;
  border-radius: 40px;
  background-color: #F2F2F2;
  padding: 5px 16px;
}

.flexItem:hover {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .flexItem {
    min-height: 39px;

    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    padding: 6px 10px;
  }
}

.active {
  border-color: #E34E44;
  color: #E34E44;
  background-color: #fff;
}

.smile {
  width: 40px;
  height: 41px;
}

@media screen and (max-width: 900px) {
  .smile {
    width: 24px;
    height: 24px;
  }
}

.whiteSpace {
  min-height: 58px;
}

.nextPadTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}