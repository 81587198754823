.bodyFlex {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    max-width: 505px;
}

.bodyFlex form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.formHeaderBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1;
}

@media screen and (max-width: 900px) {
    .formHeaderBox {
        gap: 32px;
    }
}

.nextPadTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0px 15px;
}

.errorText {
    color: #E34E44;
    text-decoration: none;
    font-family: SF Pro Display;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
}

.error {
    border-color: rgba(225, 101, 93, 0.5) !important;
    color: #E34E44
}

.inputsContainer {
    padding-left: 16px;
    border-radius: 8px;
    background-color: #fff;
    width: 343px;
    margin: 0 auto;
}

.errorContainer {
    border: 1px solid rgba(225, 101, 93, 0.5);
}

.formFlex {
    display: flex;
    flex-direction: column;
}

.formFlexError input::placeholder {
    color: #E34E44;
}

.formFlex input:first-child {
    border-radius: 8px 8px 0 0;

}

.formFlex hr {
    margin: 0;
    padding: 0;
    border-style: solid;
    border-bottom: none;
    border-color: #F2F2F2;
}

.formFlex input:last-child {
    border-radius: 0 0 8px 8px;
}

.errorFlex {
    padding-top: 8px;
    display: flex;
    gap: 4px;
}

.next {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;

    color: #7F7E84;

    text-align: center;
    cursor: pointer;

    max-width: fit-content;
    align-self: center;
    cursor: auto;
    justify-self: end;
}

.nextActive {
    cursor: pointer;
    color: #E34E44;
}

@media screen and (max-width: 900px) {
    .next {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;

        padding-bottom: 24px;
    }
}