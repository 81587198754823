.errorText {
    color: #E34E44;
    text-decoration: none;
    font-family: SF Pro Display;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
}

.error {
    color: #E34E44;
}

.inputStyle {
    box-sizing: content-box;
    width: 311px;
    height: 22px;
    padding: 11px 16px 11px 0;
    border: none;

    font-family: 'SF Pro Display', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

}

.inputStyle:focus {
    outline: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.fieldContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}