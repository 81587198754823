.nextFlex {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: end;
  gap: 16px;
}

@media screen and (max-width: 900px) {
  .nextFlex {
    padding-bottom: 24px;
  }
}

.blur {
  content: '';
  position: absolute;
  width: calc(100% - 80px);
  z-index: 100;
  min-height: 58px;
  top: -56px;
  background: linear-gradient(to top, #fff, transparent 100%);
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .blur {
    width: 100%;
  }
}

.currentStep {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #7F7E84;
}

.buttonStyle {
  border: none;
  background-color: transparent;
  max-width: fit-content;
  align-self: center;
  text-decoration: none;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

@media screen and (max-width: 900px) {
  .buttonStyle {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
}

.next {
  cursor: pointer;
  color: #E34E44;
}

.text {

  color: #7F7E84;
}