.siteLayout {
    display: flex;
    justify-content: center;
    
}

.contentContainer {
    width: 100vw;
    min-height: 100dvh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .contentContainer {
        flex-basis: 86vh;
    }
}