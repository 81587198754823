.headerFlex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-basis: 20vh;
  min-width: 100vw;
  align-content: center;
}

.forwardContainer {

  display: flex;
  align-items: center;
  padding-left: 152px;

}

@media screen and (max-width: 900px) {
  .forwardContainer {
    padding-left: 16px;

  }
}

.forward {
  width: 48px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .forward {
    width: 24px;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo {
  width: 115px;
}

@media screen and (max-width: 900px) {
  .headerLogo {
    max-width: 58px;
  }
}