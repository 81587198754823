@property --percent {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}


.pie {
    --percent: 0;
    --bold: 33px;
    --color: linear-gradient(0deg, #FF2A68 0%, #FF5E3A 100%);
    --width: 283px;
    --duration: '5s';

    min-width: var(--width);
    min-height: var(--width);

    position: relative;
    display: inline-grid;
    place-content: center;

    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 41px;
    background: var(--color);
    border-radius: 50%;
    outline: 3px solid #fff;
    outline-offset: -2px;
    box-sizing: border-box;

}

.pieText {

    position: relative;
    display: inline-grid;
    place-content: center;
    text-align: center;
    min-width: calc(var(--width) - var(--bold) * 2);
    min-height: calc(var(--width) - var(--bold) * 2);
    border-radius: 50%;
    color: #0F1214;
    background-color: #fff;
}

.pie:before {
    content: '';
    position: absolute;
    display: inline-grid;
    place-content: center;
    border: none;
    box-sizing: border-box;
    inset: 0;
    outline: 3px solid #fff;
    outline-offset: -2px;

    border-radius: 50%;
    background: conic-gradient(transparent calc(var(--percent)*1%), #F9F9F9 0);

}

.animate {
    -webkit-animation: pie both linear;
    -moz-animation: pie both linear;
    animation: pie both linear;
    -webkit-animation-duration: var(--duration);
    -moz-animation-duration: var(--duration);
    animation-duration: var(--duration);
}

@keyframes pie {
    from {
        --percent: 0;
    }

    to {
        --percent: 100;
    }
}

@-webkit-keyframes pie {
    from {
        --percent: 0;
    }

    to {
        --percent: 100;
    }
}

.pieContainer {
    padding: 0 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .pieContainer {
        padding: 0 30px;
    }
}

.pieFlex {
    padding: 48px 0 226px;
    display: flex;
    align-items: center;
    gap: 85px;
}

@media screen and (max-width: 900px) {
    .pieFlex {
        padding: 32px 15px 0;
        flex-direction: column;

        gap: 42px;
    }
}

.rightSide {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
}

@media screen and (max-width: 900px) {
    .rightSide {
        gap: 20px;
    }
}

.smileAndText {
    display: flex;
    gap: 16px;

    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #0F1214;
}

@media screen and (max-width: 900px) {
    .smileAndText {
        gap: 8px;

        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
}

.smile {
    width: 40px;
    height: 41px;
}

@media screen and (max-width: 900px) {
    .smile {
        width: 24px;
        height: 24px;
    }
}

.blur {
    content: '';
    position: absolute;
    margin: 0 auto;
    width: 100%;
    z-index: 100;
    height: 105%;
    bottom: -2px;
    background: linear-gradient(to top, #fff 100%, transparent 100%);

    -webkit-animation: blur both linear;
    -moz-animation: blur both linear;
    animation: blur both linear;
    -webkit-animation-duration: var(--duration);
    -moz-animation-duration: var(--duration);
    animation-duration: var(--duration);
}

@keyframes blur {
    from {
        height: 105%;
    }

    to {
        height: 0;
    }
}

@-webkit-keyframes blur {
    from {
        height: 105%;
    }

    to {
        height: 0;
    }
}