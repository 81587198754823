  .titleFlex {
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
  }

  @media screen and (max-width: 900px) {
    .titleFlex {
      gap: 16px;

    }
  }

  .title {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    color: #0F1214;
  }

  .title div:first-child {
    white-space: normal;
  }

  .leftAlign {
    text-align: left;
  }

  @media screen and (max-width: 900px) {
    .leftAlign {
      text-align: center;
      max-width: 359px;
    }
  }

  @media screen and (max-width: 900px) {
    .title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      color: #0F1214;

    }
  }

  .smile {
    width: 40px;
    height: 41px;
  }

  @media screen and (max-width: 900px) {
    .smile {
      width: 24px;
      height: 24px;

    }
  }

  .text {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #7F7E84;

  }

  @media screen and (max-width: 900px) {
    .text {
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #7F7E84;

    }
  }