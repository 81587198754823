.bodyFlex {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 32px;
}

@media screen and (max-width: 900px) {
  .bodyFlex {
    gap: 24px;
    padding: 0 15px;
  }
}

.scrollContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: 55vh;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 40px;
}

@media screen and (max-width: 900px) {
  .scrollContainer {
    padding: 0;
  }
}

.scrollContainer::-webkit-scrollbar {
  width: 4px;
}

@media screen and (max-width: 900px) {
  .scrollContainer::-webkit-scrollbar {
    display: none;
  }
}

.scrollContainer::-webkit-scrollbar-thumb {
  background: #C3C3C5;
  border-radius: 40px;
  width: 4px;
}

.scrollContainer::-webkit-scrollbar-track {
  display: none;
}

.scrollContainer::-webkit-scrollbar-button {
  display: none;
}

.scrollItem {
  box-sizing: border-box;
  text-align: left;
  min-width: 440px;
  min-height: 69px;

  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #0F1214;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  border: 2px solid transparent;
  border-radius: 12px;
  background-color: #F2F2F2;
  padding: 16px 14px 16px 16px;
}

.scrollItem:hover {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .scrollItem {
    min-width: 343px;
    min-height: 56px;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.active {
  color: #E34E44;
  border-color: #E34E44;
  background-color: #fff;
}

.smile {
  width: 40px;
  height: 41px;
}

@media screen and (max-width: 900px) {
  .smile {
    width: 24px;
    height: 24px;
  }
}

.whiteSpace {
  min-height: 58px;
}

.nextPadTop {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}