:root {
    --gray: #7F7E84;
    --black: #0F1214;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--black);
}

img {
    max-width: 100%;
}

main {
    max-width: 1440px;
    overflow: hidden;
    margin: 0 auto;
}

h1 {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    h1 {
        font-size: 28px;
        line-height: 34px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 128px 157px 25px 152px;
}

@media screen and (max-width: 900px) {
    .header {
        padding: 24px 16px 0;
        margin-bottom: 45px;
        justify-content: center;

    }
}

.smile {
    width: 40px;
    height: 41px;
}

@media screen and (max-width: 900px) {
    .smile {
        width: 24px;
        height: 24px;

    }
}

.rocket {
    white-space: nowrap;

}

.leftHeader {
    padding-top: 2px;
    display: flex;
    flex-direction: column;

    gap: 140px;
}

@media screen and (max-width: 900px) {
    .leftHeader {
        gap: 130px;
        text-align: center;
        align-items: center;
        flex: 1;

    }
}

.logoFlex {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
}

.logo {
    width: 115px;

}

@media screen and (max-width: 900px) {
    .logo {

        max-width: 58px
    }
}



.leftHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
}

@media screen and (max-width: 900px) {
    .leftHeaderContainer {
        align-items: center;
        gap: 130px;
        width: 100%;


    }
}

.headerTextContainer {
    max-width: 578px;
    display: flex;
    flex-direction: column;

    gap: 24px;
}

@media screen and (max-width: 900px) {
    .headerTextContainer {
        gap: 16px;
        align-items: center;


    }
}

.headerText {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: var(--gray);
}

@media screen and (max-width: 900px) {
    .headerText {
        font-size: 17px;
        line-height: 22px;
        max-width: 310px;

    }
}

.buttonsContainer {

    display: flex;
    justify-content: flex-start;
    gap: 17px;
}


.badgeLink:hover {
    cursor: pointer;
}

.redButton {
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 303px;
    height: 22px;
    padding: 14px 20px;
    background: linear-gradient(0deg, #FF2A68 0%, #FF5E3A 100%);
    box-shadow: 0px 0.83333px 3.14815px 0px rgba(240, 0, 0, 0.03),
        0px 3.66667px 6.51852px 0px rgba(240, 0, 0, 0.05),
        0px 9px 13px 0px rgba(240, 0, 0, 0.07),
        0px 17.33333px 25.48148px 0px rgba(240, 0, 0, 0.09),
        0px 29.16667px 46.85185px 0px rgba(240, 0, 0, 0.11),
        0px 45px 80px 0px rgba(240, 0, 0, 0.14);

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #FFF;
    text-decoration: none;
}

.redButton:hover {
    cursor: pointer;
}

.selectContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    box-sizing: content-box;
    width: 56px;
    height: 24px;
    padding: 4px 0px 4px 16px;

    border: none;
    border-radius: 8px;

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    color: #616161;
    text-transform: uppercase;

}

.mobileSelect {
    display: none;
}

@media screen and (max-width: 900px) {

    .mobileSelect {
        display: block;
    }
}

.optionsContainer {
    top: 36px;
    position: absolute;
    border-radius: 12px;
    overflow: hidden;

    border-radius: 12px;
    box-shadow: 0px 8px 64px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(40px);
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: content-box;
    width: 152px;
    height: 22px;
    padding: 11px 16px;

    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;

    background: rgba(255, 255, 255, 0.70);
}

.optionImage {
    box-sizing: content-box;
    width: 16px;
    height: 12px;
}

.line {
    border: none;
    border-top: 1px solid rgba(60, 60, 67, 0.36);
}

.rightHeader {
    min-width: 439px;
    min-height: 575px;
    position: relative;
    text-align: right;
}

@media screen and (max-width: 900px) {
    .rightHeader {
        display: none;
    }
}

.rectangle {
    position: absolute;
    left: 30%;
    top: 10%;
    z-index: 1;
    width: 134.459px;
    height: 422.124px;
    transform: rotate(-45deg);
    border-radius: 240px;
    border: 7px solid #E0F4FF;
    border-left-color: #F5F8CF;
}

.picTop {
    position: absolute;
    top: 0;
    left: 0;
}

.picBot {
    position: absolute;
    bottom: 0;
    right: 0;
}


.pics {
    background-color: #FFF;
    border-radius: 7px;
    max-width: 44%;
    width: auto;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.13);
    z-index: 2;
}

.scrollHeader {
    padding-left: 152px;
    font-size: 26px;
    font-weight: 500;
    line-height: 130%;
    padding-bottom: 32px;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .scrollHeader {
        padding-left: 16px;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 12px;
    }
}

.scrollContainer {
    padding-left: 152px;
    overflow-x: scroll;
    display: flex;
    gap: 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;

}

@media screen and (max-width: 900px) {
    .scrollContainer {
        padding-left: 16px;
        gap: 12px;
    }
}

.scrollContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
}


.feedback {
    box-sizing: border-box;
    min-width: 368px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 11px;
    background: #F2F2F6;



    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
}

@media screen and (max-width: 900px) {
    .feedback {
        min-width: 314px;
        gap: 12px;
    }
}

.feedbackHeader {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.feedbackName {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .feedbackName {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }
}

.feedbackDate {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--gray);
}

@media screen and (max-width: 900px) {
    .feedbackDate {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.24px;
    }
}

.feedbackText {
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.36px;
    color: var(--gray);
}

@media screen and (max-width: 900px) {
    .feedbackText {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}


.descriptionContainer {
    margin-top: 110px;
    padding-left: 152px;
    gap: 150px;
    display: flex;
}

@media screen and (max-width: 900px) {
    .descriptionContainer {
        flex-direction: column;
        margin-top: 48px;
        padding: 0 16px;
        gap: 40px;

    }
}

@media screen and (max-width: 450px) {
    .descriptionContainer {
        align-items: center;

    }
}

.descriptionHeader {
    font-size: 28px;
    font-weight: 500;
    line-height: 22px;
    padding-bottom: 32px;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .descriptionHeader {
        font-size: 18px;
        padding-bottom: 12px;
    }
}

.descriptionLeft {
    max-width: 656px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.36px;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .descriptionLeft {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

.descriptionRight {
    position: relative;
    max-width: 550px;
}

@media screen and (max-width: 900px) {
    .descriptionRight {
        position: static;

    }
}

.phoneImg {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .phoneImg {
        display: none;
    }


}

.relativeButtons {
    position: absolute;
    bottom: 18%;
    left: 15%;
    background-color: transparent;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .relativeButtons {
        position: static;
        text-align: left;

    }
}


.buttonsHeader {
    font-size: 28px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 12px;
    color: var(--black);

}

@media screen and (max-width: 900px) {
    .buttonsHeader {
        font-size: 18px;
        line-height: 22px
    }
}


.footer {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding-left: 152px;
}

@media screen and (max-width: 900px) {
    .footer {

        gap: 12px;
        padding: 0 16px;
        align-items: start;

    }
}

@media screen and (max-width: 450px) {
    .footer {

        margin: 0 auto;

    }
}

.supportContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media screen and (max-width: 900px) {
    .supportContainer {

        gap: 12px;


    }
}

.footerHeader {
    padding-top: 40px;
    font-size: 28px;
    font-weight: 500;
    line-height: 22px;
    align-self: baseline;
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .footerHeader {
        font-size: 18px;

    }
}



.footerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    width: 340px;
    gap: 8px;

    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    color: var(--black);

    border-radius: 8px;
    background: #FFF;

    box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.05),
        0px 10px 19px 2px rgba(0, 0, 0, 0.02),
        0px -3px 9px 0px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 900px) {
    .footerButton {
        align-self: stretch;
        min-height: 50px;

    }
}

.footerPadding {
    padding: 36px 0 84px 0;
}

.withGap>div {
    margin-top: 8px;
}

.footerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

@media screen and (max-width: 450px) {
    .footerFlex {
        flex-direction: column;
        align-items: start;
        gap: 44px;
    }
}

.copyright {

    color: var(--gray);
    font-size: 16px;
    line-height: 21px;
}

@media screen and (max-width: 900px) {
    .copyright {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.24px;
        text-align: center;

    }
}

@media screen and (max-width: 450px) {
    .copyright {

        order: 1;
    }
}

.linksContainer {
    flex: 1;
    text-align: right;
    padding: 0 16px;
}

@media screen and (max-width: 900px) {
    .linksContainer {

        padding: 0
    }
}


.footerLink {
    color: #0F1214;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .footerLink {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.24px;

    }
}

