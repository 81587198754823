.bodyFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 32px;
}

@media screen and (max-width: 900px) {
  .bodyFlex {
    gap: 24px;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
}

@media screen and (max-width: 900px) {
  .gridContainer {
    gap: 6px;
  }
}

.gridItem {
  box-sizing: content-box;
  text-align: center;
  max-width: 118px;

  font-family: 'SF Pro Display';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: #0F1214;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  border-radius: 12px;
  border: 2px solid transparent;
  background-color: #F2F2F2;
  padding: 24px;
}

.gridItem:hover {
  cursor: pointer
}

@media screen and (max-width: 900px) {
  .gridItem {
    max-width: 82px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;

    padding: 16px 14px;
  }
}

.selected {
  border: 2px solid #E34E44;
  background-color: #FFF;
  color: #E34E44;
}

.smile {
  width: 44px;
  height: 45px;
}

@media screen and (max-width: 900px) {
  .smile {
    width: 24px;
    height: 24px;
  }
}

.nextPadTop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}