.bodyFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 50px;
    padding-bottom: 75px;
}

@media screen and (max-width: 900px) {
    .bodyFlex {
        gap: 16px;
    }
}

.resultsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

}

@media screen and (max-width: 900px) {
    .resultsGrid {
        position: relative;
        grid-template-columns: 1fr;
        padding: 0;
    }
}

.resultsItem {
    box-sizing: content-box;
    width: 311px;

    display: flex;
    flex-direction: column;
    gap: 13px;

    border-radius: 11px;
    background-color: #fff;
    padding: 16px;
}

.itemHeader {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #0F1214;
}

.flexItem {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #0F1214;

    display: flex;
    align-items: center;
    gap: 8px;
}

.botFlex {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .botFlex {
        display: none;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.buttonsHeader {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #0F1214;
}

.buttonsText {
    max-width: 310px;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #7F7E84;
}

.buttonsContainer {
    display: flex;
    justify-items: center;
    gap: 17px;
}

.buttonFlex {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
}

.smile {
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 900px) {
    .redButtonBlurTop {
        padding-top: 25px;
        position: absolute;
        width: 100%;
        bottom: -50px;

        background: linear-gradient(179deg, rgba(255, 255, 255, 0.00) 0.52%,
                rgba(255, 255, 255, 0.30) 6.06%, #FFF 24.33%);
    }
}

.redButton {
    display: none;
}

@media screen and (max-width: 900px) {
    .redButton {
        display: block;
        box-sizing: border-box;
        align-self: stretch;

        border-radius: 8px;
        padding: 14px 24px;
        border: none;
        text-align: center;
        text-decoration: none;

        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #fff;
        background: linear-gradient(0deg, #FF2A68 0%, #FF5E3A 100%);

        box-shadow: 0px 0.83333px 3.14815px 0px rgba(240, 0, 0, 0.03),
            0px 3.66667px 6.51852px 0px rgba(240, 0, 0, 0.05),
            0px 9px 13px 0px rgba(240, 0, 0, 0.07),
            0px 17.33333px 25.48148px 0px rgba(240, 0, 0, 0.09),
            0px 29.16667px 46.85185px 0px rgba(240, 0, 0, 0.11),
            0px 45px 80px 0px rgba(240, 0, 0, 0.14);

    }
}