.footer {

  text-align: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #C3C3C5;
  flex-basis: 8vh;
}

@media screen and (max-width: 900px) {
  .footer {
    display: none;
  }
}